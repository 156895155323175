<template>
    <ion-page>
        <ion-tabs>
            <ion-router-outlet />
            <ion-tab-bar
                v-if="!isWebAndNotDevelopment"
                slot="bottom"
                class="minimal-tab-bar"
            >
                <ion-tab-button
                    tab="contents"
                    href="/tabs/content-list"
                    class="tab-button"
                    :class="{ 'tab-selected': isTabSelected('contents') }"
                    @click="handleContentsTabClick"
                >
                    <ion-icon
                        aria-hidden="true"
                        :icon="homeOutline"
                    />
                </ion-tab-button>
                <ion-tab-button
                    v-if="store.isRestaurantUser && store.hasCurrentCompany"
                    tab="products"
                    href="/tabs/product-view"
                    class="tab-button"
                    :class="{ 'tab-selected': isTabSelected('products') }"

                    @click="handleProductsTabClick"
                >
                    <ion-icon
                        aria-hidden="true"
                        :icon="basketOutline"
                    />
                </ion-tab-button>

                <ion-tab-button
                    v-if="store.hasCurrentCompany && store.isRestaurantUser"
                    tab="cart"
                    href="/tabs/cart-view"
                    class="tab-button"
                    :class="{ 'tab-selected': isTabSelected('cart') }"
                >
                    <ion-icon
                        aria-hidden="true"
                        :icon="cartOutline"
                    />
                    <ion-badge
                        v-if="delayedCartItemCount"
                        :class="{ 'animate-badge': animateBadge }"
                    >
                        {{ delayedCartItemCount }}
                    </ion-badge>
                </ion-tab-button>

                <ion-tab-button
                    v-if="store.isSupplierUser"
                    tab="post"
                    href="/tabs/post-view"
                    class="tab-button"
                    :class="{ 'tab-selected': isTabSelected('post') }"
                >
                    <ion-icon
                        aria-hidden="true"
                        :icon="addOutline"
                    />
                </ion-tab-button>
                <ion-tab-button
                    tab="profile"
                    :href="profileTabHref"
                    class="tab-button"
                    :class="{ 'tab-selected': isTabSelected('profile') }"
                >
                    <ion-icon
                        aria-hidden="true"
                        :icon="personOutline"
                    />
                </ion-tab-button>

                <ion-tab-button
                    tab="notification"
                    href="/tabs/notification-view"
                    class="tab-button"
                    :class="{ 'tab-selected': isTabSelected('notification') }"
                >
                    <ion-icon
                        aria-hidden="true"
                        :icon="notificationsOutline"
                    />
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
        <FoodPreferencesModal ref="foodPreferencesModalRef" />
    </ion-page>
</template>

<script setup lang="ts">
import {useRouter} from 'vue-router';
import {computed} from 'vue';
import {Capacitor} from '@capacitor/core';
import config from '../config'; // Adjust the path as necessary
import {useCartStore} from '@/shared/store/cartStore'; // Adjust the path as necessary
import {watch, ref} from 'vue';
const animateBadge = ref(false);

const cartStore = useCartStore();

const isWebAndNotDevelopment = Capacitor.getPlatform() === 'web' && !config.development;
import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonBadge,
} from '@ionic/vue';
import {basketOutline, homeOutline, personOutline, notificationsOutline, addOutline, cartOutline} from 'ionicons/icons';
import {useUserStore} from '../stores/user';
import FoodPreferencesModal from '@/components/FoodPreferencesModal.vue';
import {onMounted} from 'vue';
const foodPreferencesModalRef = ref(null);

const router = useRouter();


const delayedCartItemCount = ref(cartStore.cartItemCount);
// if we are not in web
if (!isWebAndNotDevelopment) {
    cartStore.fetchCartItems();
}
watch(() => cartStore.cartItemCount, (newCount, oldCount) => {
    if (newCount > oldCount) {
        // Set the animation trigger

        setTimeout(() => {
            delayedCartItemCount.value = newCount;
            animateBadge.value = true;
        }, 500); // Match this delay to the start of the animation// Delay resetting the animation trigger to allow the animation to be visible
        setTimeout(() => {
            animateBadge.value = false;
        }, 3000); // Consider the animation duration + delay

        // Delay the badge number update to match the animation start
    } else {
        // For cases where the count decreases or instant update is needed without animation
        delayedCartItemCount.value = newCount;
    }
});


const profileTabHref = computed(() => {
    return store.isRestaurantUser ? '/tabs/personal-profile' : '/profile-view/';
});
const handleProductsTabClick = () => {
    if (router.currentRoute.value.path === '/tabs/product-view') {
        const topElement = document.getElementById('topOfProducts');
        if (topElement) {
            topElement.scrollIntoView({behavior: 'smooth'});
        }
    } else {
        router.push('/tabs/product-view');
    }
};

const handleContentsTabClick = () => {
    if (router.currentRoute.value.path === '/tabs/content-list') {
        // Use vanilla JavaScript to scroll to the 'topOfPage' element
        const topElement = document.getElementById('topOfPage');
        if (topElement) {
            topElement.scrollIntoView({behavior: 'smooth'});
        }
    } else {
        // Otherwise, navigate to the content-list page
        router.push('/tabs/content-list');
    }
};

const store = useUserStore();
const isTabSelected = (tab: string) => {
    const currentPath = router.currentRoute.value.path;
    const pathMap: Record<string, string[]> = {
        'contents': ['/tabs/content-list'],
        'products': ['/tabs/product-view'],
        'cart': ['/tabs/cart-view'],
        'post': ['/tabs/post-view'],
        'profile': ['/tabs/personal-profile', '/profile-view/'],
        'notification': ['/tabs/notification-view'],
    };
    return pathMap[tab]?.includes(currentPath) || false;
};
const showModal = () => {
    let modalShown = undefined;

    try {
        modalShown = sessionStorage.getItem('loadFoodPreferences');
    } catch (error) {
        console.log('loadFoodPreferences not found');
    }

    store.loadFoodPreferences().then(() => {
        if (store.isRestaurantUser && !store.isSupplierUser && !store.hasCompletedOnboarding && !modalShown) {
            if (foodPreferencesModalRef.value) {
                foodPreferencesModalRef.value.openPreferencesModal(true);
            } else {
                console.warn('foodPreferencesModalRef.value is null or undefined');
            }

            try {
                sessionStorage.setItem('loadFoodPreferences', 'true');
            } catch (error) {
                console.error('Error setting item in sessionStorage:', error);
            }
        } else {
            console.log('Conditions not met to open preferences modal');
        }
    }).catch((error) => {
        console.error('Error in loadFoodPreferences:', error);
    });

    console.log('Exiting showModal function');
};

onMounted(() => {
    console.log('Component mounted');
    if (Capacitor.getPlatform() !== 'web') {
        showModal();
    }
});
</script>

<style scoped>
.minimal-tab-bar {
    height: 48px; /* Set height as you desire */
    --background: white;
}

.tab-button {
    flex-direction: column;
    justify-content: center;
}


@keyframes badge-pop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.animate-badge {
    animation: badge-pop 0.5s ease;
}

</style>
