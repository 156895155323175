<template>
    <ion-modal
        :is-open="userStore.showCreateBusinessModal"
        @ion-modal-did-dismiss="closeModal"
    >
        <ion-header>
            <ion-toolbar>
                <ion-title>Crea Azienda</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="closeModal">
                        <ion-icon :icon="closeOutline" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-item>
                <ion-label position="stacked">
                    Partita IVA:
                </ion-label>
                <ion-input
                    v-model="newCompany.partitaIva"
                    required
                    aria-label="Partita IVA"
                />
            </ion-item>

            <ion-item>
                <ion-label position="stacked">
                    Nome dell'azienda:
                </ion-label>
                <ion-input
                    v-model="newCompany.name"
                    required
                    aria-label="Nome dell'azienda"
                />
            </ion-item>

            <ion-item>
                <ion-label position="stacked">
                    Nome utente:
                </ion-label>
                <ion-input
                    v-model="newCompany.username"
                    required
                    aria-label="Nome utente"
                />
            </ion-item>
            <ion-item>
                <ion-label position="stacked">
                    Descrizione:
                </ion-label>
                <ion-input
                    v-model="newCompany.descrizione"
                    required
                    aria-label="Descrizione"
                />
            </ion-item>
            <ion-item>
                <ion-label position="stacked">
                    Numero di contatto:
                </ion-label>
                <ion-input
                    v-model="newCompany.contactNumber"

                    aria-label="Numero di contatto"
                />
            </ion-item>
            <ion-item>
                <ion-label position="stacked">
                    Email di contatto:
                </ion-label>
                <ion-input
                    v-model="newCompany.contactEmail"
                    type="email"
                    aria-label="Email di contatto"
                />
            </ion-item>
            <ion-item>
                <ion-label position="stacked">
                    Indirizzo:
                </ion-label>
                <ion-input
                    v-model="newCompany.address"
                    required
                    aria-label="Indirizzo"
                />
            </ion-item>
            <ion-item>
                <ion-label position="stacked">
                    Città:
                </ion-label>
                <ion-input
                    v-model="newCompany.citta"
                    required
                    aria-label="Città"
                />
            </ion-item>
            <ion-item>
                <ion-label position="stacked">
                    CAP:
                </ion-label>
                <ion-input
                    v-model="newCompany.cap"
                    required
                    aria-label="CAP"
                />
            </ion-item>
            <ion-item @click="openProvinceSelector">
                <ion-label position="stacked">
                    Provincia:
                </ion-label>{{ selectedProvince || 'Seleziona una Provincia' }}
            </ion-item>
            <ProvinceSelectorModal
                ref="provinceSelector"
                :provinces="userStore.provinces"
                @update:selected-province="handleProvinceSelection"
            />
            <ion-button
                expand="block"
                fill="outline"
                @click="creaAzienda"
            >
                Crea azienda
            </ion-button>
            <ion-loading
                :is-open="isLoading"
                message="Sending invitation..."
            />
        </ion-content>
    </ion-modal>
</template>

<script setup lang="ts">
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
    IonLoading,
    alertController,
} from '@ionic/vue';
import {closeOutline} from 'ionicons/icons';
import {ref} from 'vue';
import {useUserStore} from '../stores/user';
import {useCustomerStore} from '@/stores/customer';

import ProvinceSelectorModal from './ProvinceSelectorModal.vue';

const provinceSelector = ref(null);
const selectedProvince = ref('');
const isLoading = ref(false);

const openProvinceSelector = async () => {
    if (userStore.provinces.length === 0) {
        await userStore.fetchProvinces();
    }
    provinceSelector.value.isOpen = true;
};

const handleProvinceSelection = (province: string) => {
    selectedProvince.value = province;
};

const validateEmail = (email: string) => {
    const re = /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
};

const validatePartitaIva = (partitaIva: string) => {
    if (partitaIva.length !== 11 || !/^\d+$/.test(partitaIva)) {
        return false;
    }

    const cifre = partitaIva.split('').map((c) => parseInt(c, 10));
    const totaleDispari = cifre.filter((_, i) => i % 2 === 0).reduce((acc, n) => acc + n, 0);
    const totalePari = cifre.filter((_, i) => i % 2 !== 0)
        .map((n) => (n * 2 > 9 ? n * 2 - 9 : n * 2))
        .reduce((acc, n) => acc + n, 0);
    const totale = totaleDispari + totalePari;

    return totale % 10 === 0;
};

const userStore = useUserStore();
const newCompany = ref({
    name: '',
    username: '',
    partitaIva: '',
    contactEmail: '',
    descrizione: '',
    address: '',
    citta: '',
    cap: '',
    provincia: '',
    contactNumber: '',
});

const closeModal = () => {
    userStore.toggleCreateBusinessModal(false);
    newCompany.value = {
        name: '',
        username: '',
        partitaIva: '',
        contactEmail: '',
        descrizione: '',
        address: '',
        citta: '',
        cap: '',
        provincia: '',
        contactNumber: '',
    };
};

const creaAzienda = async () => {
    const customerStore = useCustomerStore();

    if (!validateEmail(newCompany.value.contactEmail)) {
        await alertController.create({
            header: 'Errore',
            message: 'L\'email fornita non è valida. Per favore, correggila.',
            buttons: ['OK'],
        }).then((alert) => alert.present());
        return;
    }

    if (!validatePartitaIva(newCompany.value.partitaIva)) {
        await alertController.create({
            header: 'Errore',
            message: 'La Partita IVA fornita non è valida. Per favore, correggila.',
            buttons: ['OK'],
        }).then((alert) => alert.present());
        return;
    }

    const duplicateFields = {
        username: newCompany.value.username,
        contactNumber: newCompany.value.contactNumber,
        contactEmail: newCompany.value.contactEmail,
        VAT_Number: newCompany.value.partitaIva,
    };

    const isDuplicate = await customerStore.checkForDuplicateCompany(duplicateFields);
    if (isDuplicate) {
        await alertController.create({
            header: 'Errore',
            message: 'I dati forniti sono già presenti nel database. Per favore, correggili.',
            buttons: ['OK'],
        }).then((alert) => alert.present());
        return;
    }

    const alert = await alertController.create({
        header: 'Vuoi creare un\'azienda?',
        message: 'Se clicchi sì, verrai disconnesso dall\'azienda corrente.',
        buttons: [
            {
                text: 'No',
                role: 'cancel',
                handler: () => {
                    console.log('Invitation cancelled');
                },
            },
            {
                text: 'Si',
                handler: async () => {
                    const input = {
                        VAT_Number: newCompany.value.partitaIva,
                        Name: newCompany.value.name,
                        username: newCompany.value.username,
                        Description: newCompany.value.descrizione,
                        Address: newCompany.value.address,
                        Province: selectedProvince.value,
                        Active: true,
                        users: {
                            connect: [
                                {
                                    where: {
                                        node: {
                                            F2_ID: userStore.userAdditionalData?.F2_ID,
                                        },
                                    },
                                },
                            ],
                        },
                    };

                    await customerStore.createCustomerWithUser([input]);
                    closeModal();
                    window.location.reload();
                },
            },
        ],
    });

    await alert.present();
};
</script>
