import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {IonApp, IonRouterOutlet, IonSplitPane} from '@ionic/vue';
import ReviewsOnProduct from './components/ReviewsOnProduct.vue';
import ProductModal from './components/ProductOnCart.vue';
import CreateBusinessModal from '@/components/CreateBusiness.vue';
import OrderDetailModal from '@/components/OrderDetailModal.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonSplitPane), {
        when: "false",
        "content-id": "main-content"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet), { id: "main-content" }),
          _createVNode(ProductModal),
          _createVNode(CreateBusinessModal),
          _createVNode(ReviewsOnProduct),
          _createVNode(OrderDetailModal)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})