<template>
    <ion-modal
        :key="modalKey"
        ref="modalRef"
        :is-open="cartModalStore.isCartModalVisible"
        :initial-breakpoint="cartModalStore.initialModalBreakpoint"
        :breakpoints="[0.5, 0.7]"
        @did-dismiss="cartModalStore.hideCartModal"
    >
        <ion-content
            v-if="cartModalStore.selectedProduct"
            class="ion-padding"
        >
            <div class="modal-content">
                <!-- Name and Image Container -->
                <div class="name-image-container">
                    <img
                        :src="`${filesApiEndpoint}${cartModalStore.selectedProduct?.img[0]}/xl`"
                        alt="Product Image"
                        class="product-image"
                        @error="event => getCategoryImagePath(event)"
                    >
                    <div class="name-segment-container">
                        <div class="product-name">
                            <span>{{ cartModalStore.selectedProduct?.Name }}</span>
                        </div>
                        <template v-if="showSegments">
                            <div class="segment-wrapper">
                                <div class="segment-with-icon">
                                    <ion-segment
                                        :value="cartModalStore.selectedUnityPrice"
                                        class="custom-segment"
                                        @ionChange="updateUnityPrice($event.detail.value)"
                                    >
                                        <ion-segment-button
                                            value="PZ"
                                            class="custom-segment-button"
                                        >
                                            PZ
                                        </ion-segment-button>
                                        <ion-segment-button
                                            value="KG"
                                            class="custom-segment-button"
                                        >
                                            KG
                                        </ion-segment-button>
                                    </ion-segment>
                                    <ion-icon
                                        v-if="cartModalStore.selectedUnityPrice === 'PZ'"
                                        id="pz-info-popover"
                                        :icon="informationCircle"
                                        class="info-icon"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <!-- Quantity -->
                <div class="quantity-full-width">
                    <label for="quantity">
                        <div v-if="!showSegments">
                            Quantità ({{ cartModalStore.selectedProduct?.UnityPrice }}):
                        </div>
                        <div v-else-if="showMediumUnityWeight">
                            Quantità ({{ cartModalStore.selectedProduct?.MediumUnityWeight }}KG/PZ):
                        </div>
                        <div v-else>
                            Quantità (KG):
                        </div>
                    </label>
                    <ion-button
                        fill="outline"
                        @click="decreaseQuantity"
                    >
                        -
                    </ion-button>
                    <input
                        id="quantity"
                        v-model="cartModalStore.quantity"
                        type="text"
                        max="100"
                        class="quantity-input custom-input"
                        pattern="\d*\.?\d*"
                        inputmode="decimal"
                    >
                    <ion-button
                        fill="outline"
                        @click="increaseQuantity"
                    >
                        +
                    </ion-button>
                </div>
                <hr class="line-separator">

                <!-- Total -->
                <div class="total-full-width">
                    <span>Totale:</span>
                    <span>{{ cartModalStore.totalPrice }} €</span>
                </div>

                <ion-textarea
                    v-if="cartModalStore.isNoteVisible"
                    slot="content"
                    v-model="cartModalStore.noteContent"
                    class="ion-padding product-note-textarea"
                    placeholder="Scrivi qui la tua nota..."
                />

                <div class="button-container">
                    <ion-button
                        fill="solid"
                        :disabled="cartModalStore.noteContent?.length"
                        @click="toggleNoteVisibility"
                    >
                        Nota
                    </ion-button>
                    <ion-button
                        v-if="!isProductInCart"
                        fill="solid"
                        :disabled="isAddingToCart"
                        @click="addToCartAndClose"
                    >
                        Aggiungi al carrello
                    </ion-button>
                    <ion-button
                        v-else
                        fill="solid"
                        :disabled="isAddingToCart"
                        @click="addToCartAndClose"
                    >
                        Salva
                    </ion-button>
                </div>
            </div>
        </ion-content>
        <ion-popover
            trigger="pz-info-popover"
            trigger-action="click"
        >
            <ion-content class="ion-padding">
                <p>Il peso è indicativo. Il prodotto verrà pesato al momento della preparazione e potrebbe subire lievi variazioni.</p>
            </ion-content>
        </ion-popover>
    </ion-modal>
</template>

<script setup lang="ts">
import {IonModal,
    IonContent,
    IonButton,
    toastController,
    IonTextarea,
    IonSegmentButton,
    IonSegment,
    IonIcon,
    IonPopover} from '@ionic/vue';
import {ref, computed} from 'vue';
import config from '@/config';
import {useCartModalStore} from '@/stores/cartModalStore';
import {useCartStore} from '@/shared/store/cartStore';
import {informationCircle} from 'ionicons/icons';

const cartStore = useCartStore();

const isProductInCart = computed(() => {
    return cartStore.cartItems.some((item) => item.product.uid === cartModalStore.selectedProduct?.uid);
});
const modalRef = ref(null); // A ref to hold the modal instance
const isAddingToCart = ref(false);
const toggleNoteVisibility = () => {
    cartModalStore.toggleNoteVisibility();

    modalRef?.value?.$el?.setCurrentBreakpoint(cartModalStore.initialModalBreakpoint);
};

const cartModalStore = useCartModalStore();

const filesApiEndpoint = config.f2BaseURL + config.filesApi;

const modalKey = ref(0); // Add a key to control the rerendering of the modal

const increaseQuantity = () => {
    // Parse the current quantity to a number, increment it, and convert it back to a string
    const currentQuantity = parseFloat(cartModalStore.quantity);
    cartModalStore.quantity = (currentQuantity + 1).toString();
};

const decreaseQuantity = () => {
    // Parse the current quantity to a number, decrement it if it's greater than 1, and convert it back to a string
    const currentQuantity = parseFloat(cartModalStore.quantity);
    if (currentQuantity > 1) {
        cartModalStore.quantity = (currentQuantity - 1).toString();
    }
};

const showSegments = computed(() => {
    const product = cartModalStore.selectedProduct;
    return product?.category?.SoldByUnit && product?.UnityPrice === 'KG';
});

const showMediumUnityWeight = computed(() => {
    return showSegments.value && cartModalStore.selectedUnityPrice === 'PZ';
});

function updateUnityPrice(value: string) {
    cartModalStore.selectedUnityPrice = value;
}


async function addToCartAndClose() {
    isAddingToCart.value = true;
    await cartModalStore.addProductToCart();
    const toast = await toastController.create({
        message: 'L\'articolo è stato aggiunto al carrello.',
        duration: 1500,
        position: 'top',
    });
    toast.present();
    isAddingToCart.value = false;
}
const getCategoryImagePath = (event: Event) => {
    // Assuming you have a ref to the modal instance
    const imgElement = event.target as HTMLImageElement;
    try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const imagePath = require(`@/assets/img/products/${cartModalStore.selectedProduct?.Category}.png`);
        imgElement.src = imagePath;
    } catch (e) {
        console.error('Failed to load category image:', cartModalStore.selectedProduct, '; Error:', e);
        // Fallback to a default image if the category-specific image also fails to load
        imgElement.src = require('@/assets/img/products/default.png');
    }
};
</script>


<style scoped>
.modal-layout {
  display: flex;
  flex-direction: column;
  height: 100%; /* Make sure it takes the full height */
  padding: 20px;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.modal-content {
  flex-grow: 1;
  overflow-y: auto; /* Adds scroll to the content if it exceeds the available space */
}

/* This class is for the div that wraps your two buttons */
.button-container {
    display: flex;
    justify-content: space-between; /* Adjusts button spacing */
    width: 100%; /* Ensure the container takes full width */
    padding: 10px 0; /* Add some padding for better appearance */
}

.custom-segment-button {
  --button-width: 40px;
  --button-height: 20px;
  font-size: 0.75rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-segment-button ion-icon {
  margin-left: 4px;
  font-size: 14px;
  cursor: pointer;
}
.button-container ion-button:first-child {
    margin-right: auto; /* Push the "Nota" button to the left */
}

.button-container ion-button:last-child {
    margin-left: auto; /* Push the "Aggiungi al carrello" button to the right */
}

.name-image-container {
    display: flex;
    align-items: flex-start; /* Align items to the start */
    transition: transform 0.3s ease-in-out;
}

.name-segment-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px; /* Add some space between the image and the text */
    flex-grow: 1; /* Allow the container to grow */
}

.product-name {
    text-align: left; /* Align text to the left */
    margin-top: 0; /* Ensure it starts from the top */
}

.segment-wrapper {
  margin-top: auto;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
  text-align: left;
}

.segment-with-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-segment {
  --button-width: 40px;
  --button-height: 20px;
  --indicator-height: 2px;
  --indicator-width: 40px;
  --border-radius: 4px;
  flex-grow: 1;
  margin-right: 10px;
}

.info-icon {
  font-size: 20px;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.quantity-full-width, .total-full-width {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  font-weight: bold;
  transition: transform 0.3s ease-in-out;
}

.product-image {
  max-width: 115px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.add-to-cart-button {
  width: auto;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}


.quantity-full-width label {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
}

.quantity-input {
    border: 1px solid #acad67;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 0 5px;
    text-align: center;
    width: 60px;
    height: 30px;
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease-in-out;
}

.quantity-input:focus {
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.2), 0 0 0 2px #acad67;
}

.product-name span {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}

.modal-content, .quantity-full-width, .total-full-width, .product-image, .quantity-input {
  /* Apply smooth transitions with cubic-bezier for fine-tuned control */
  transition: transform 2s cubic-bezier(0.22, 0.61, 0.36, 1), opacity 3s ease-in-out;
}

/* Enhanced modal transition for smoother appearance and disappearance */
.ion-modal {
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.name-image-container, .button-container {
  /* Transition for elements within the modal for smoother repositioning */
  transition: transform 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.small-modal {
  --height: 50vh;
}

.large-modal {
  --height: 80vh;
}

.product-note-textarea {
  border: 0.5px solid rgb(90, 86, 86);
  border-radius: 8px;
}
.line-separator {
    border: 0;
    height: 1px;
    background: #acad67;
    margin: 20px 0;
}

.custom-input {
  background-color: #ffffff;
  color: #000000;
}

</style>
