import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "name-image-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "name-segment-container" }
const _hoisted_5 = { class: "product-name" }
const _hoisted_6 = {
  key: 0,
  class: "segment-wrapper"
}
const _hoisted_7 = { class: "segment-with-icon" }
const _hoisted_8 = { class: "quantity-full-width" }
const _hoisted_9 = { for: "quantity" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "total-full-width" }
const _hoisted_14 = { class: "button-container" }

import {IonModal,
    IonContent,
    IonButton,
    toastController,
    IonTextarea,
    IonSegmentButton,
    IonSegment,
    IonIcon,
    IonPopover} from '@ionic/vue';
import {ref, computed} from 'vue';
import config from '@/config';
import {useCartModalStore} from '@/stores/cartModalStore';
import {useCartStore} from '@/shared/store/cartStore';
import {informationCircle} from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductOnCart',
  setup(__props) {

const cartStore = useCartStore();

const isProductInCart = computed(() => {
    return cartStore.cartItems.some((item) => item.product.uid === cartModalStore.selectedProduct?.uid);
});
const modalRef = ref(null); // A ref to hold the modal instance
const isAddingToCart = ref(false);
const toggleNoteVisibility = () => {
    cartModalStore.toggleNoteVisibility();

    modalRef?.value?.$el?.setCurrentBreakpoint(cartModalStore.initialModalBreakpoint);
};

const cartModalStore = useCartModalStore();

const filesApiEndpoint = config.f2BaseURL + config.filesApi;

const modalKey = ref(0); // Add a key to control the rerendering of the modal

const increaseQuantity = () => {
    // Parse the current quantity to a number, increment it, and convert it back to a string
    const currentQuantity = parseFloat(cartModalStore.quantity);
    cartModalStore.quantity = (currentQuantity + 1).toString();
};

const decreaseQuantity = () => {
    // Parse the current quantity to a number, decrement it if it's greater than 1, and convert it back to a string
    const currentQuantity = parseFloat(cartModalStore.quantity);
    if (currentQuantity > 1) {
        cartModalStore.quantity = (currentQuantity - 1).toString();
    }
};

const showSegments = computed(() => {
    const product = cartModalStore.selectedProduct;
    return product?.category?.SoldByUnit && product?.UnityPrice === 'KG';
});

const showMediumUnityWeight = computed(() => {
    return showSegments.value && cartModalStore.selectedUnityPrice === 'PZ';
});

function updateUnityPrice(value: string) {
    cartModalStore.selectedUnityPrice = value;
}


async function addToCartAndClose() {
    isAddingToCart.value = true;
    await cartModalStore.addProductToCart();
    const toast = await toastController.create({
        message: 'L\'articolo è stato aggiunto al carrello.',
        duration: 1500,
        position: 'top',
    });
    toast.present();
    isAddingToCart.value = false;
}
const getCategoryImagePath = (event: Event) => {
    // Assuming you have a ref to the modal instance
    const imgElement = event.target as HTMLImageElement;
    try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const imagePath = require(`@/assets/img/products/${cartModalStore.selectedProduct?.Category}.png`);
        imgElement.src = imagePath;
    } catch (e) {
        console.error('Failed to load category image:', cartModalStore.selectedProduct, '; Error:', e);
        // Fallback to a default image if the category-specific image also fails to load
        imgElement.src = require('@/assets/img/products/default.png');
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    key: modalKey.value,
    ref_key: "modalRef",
    ref: modalRef,
    "is-open": _unref(cartModalStore).isCartModalVisible,
    "initial-breakpoint": _unref(cartModalStore).initialModalBreakpoint,
    breakpoints: [0.5, 0.7],
    onDidDismiss: _unref(cartModalStore).hideCartModal
  }, {
    default: _withCtx(() => [
      (_unref(cartModalStore).selectedProduct)
        ? (_openBlock(), _createBlock(_unref(IonContent), {
            key: 0,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("img", {
                    src: `${filesApiEndpoint}${_unref(cartModalStore).selectedProduct?.img[0]}/xl`,
                    alt: "Product Image",
                    class: "product-image",
                    onError: _cache[0] || (_cache[0] = event => getCategoryImagePath(event))
                  }, null, 40, _hoisted_3),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(_unref(cartModalStore).selectedProduct?.Name), 1)
                    ]),
                    (showSegments.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_unref(IonSegment), {
                              value: _unref(cartModalStore).selectedUnityPrice,
                              class: "custom-segment",
                              onIonChange: _cache[1] || (_cache[1] = ($event: any) => (updateUnityPrice($event.detail.value)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonSegmentButton), {
                                  value: "PZ",
                                  class: "custom-segment-button"
                                }, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createTextVNode(" PZ ")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(IonSegmentButton), {
                                  value: "KG",
                                  class: "custom-segment-button"
                                }, {
                                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                                    _createTextVNode(" KG ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"]),
                            (_unref(cartModalStore).selectedUnityPrice === 'PZ')
                              ? (_openBlock(), _createBlock(_unref(IonIcon), {
                                  key: 0,
                                  id: "pz-info-popover",
                                  icon: _unref(informationCircle),
                                  class: "info-icon"
                                }, null, 8, ["icon"]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, [
                    (!showSegments.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Quantità (" + _toDisplayString(_unref(cartModalStore).selectedProduct?.UnityPrice) + "): ", 1))
                      : (showMediumUnityWeight.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Quantità (" + _toDisplayString(_unref(cartModalStore).selectedProduct?.MediumUnityWeight) + "KG/PZ): ", 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, " Quantità (KG): "))
                  ]),
                  _createVNode(_unref(IonButton), {
                    fill: "outline",
                    onClick: decreaseQuantity
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" - ")
                    ])),
                    _: 1
                  }),
                  _withDirectives(_createElementVNode("input", {
                    id: "quantity",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(cartModalStore).quantity) = $event)),
                    type: "text",
                    max: "100",
                    class: "quantity-input custom-input",
                    pattern: "\\d*\\.?\\d*",
                    inputmode: "decimal"
                  }, null, 512), [
                    [_vModelText, _unref(cartModalStore).quantity]
                  ]),
                  _createVNode(_unref(IonButton), {
                    fill: "outline",
                    onClick: increaseQuantity
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" + ")
                    ])),
                    _: 1
                  })
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("hr", { class: "line-separator" }, null, -1)),
                _createElementVNode("div", _hoisted_13, [
                  _cache[8] || (_cache[8] = _createElementVNode("span", null, "Totale:", -1)),
                  _createElementVNode("span", null, _toDisplayString(_unref(cartModalStore).totalPrice) + " €", 1)
                ]),
                (_unref(cartModalStore).isNoteVisible)
                  ? (_openBlock(), _createBlock(_unref(IonTextarea), {
                      key: 0,
                      slot: "content",
                      modelValue: _unref(cartModalStore).noteContent,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(cartModalStore).noteContent) = $event)),
                      class: "ion-padding product-note-textarea",
                      placeholder: "Scrivi qui la tua nota..."
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_unref(IonButton), {
                    fill: "solid",
                    disabled: _unref(cartModalStore).noteContent?.length,
                    onClick: toggleNoteVisibility
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" Nota ")
                    ])),
                    _: 1
                  }, 8, ["disabled"]),
                  (!isProductInCart.value)
                    ? (_openBlock(), _createBlock(_unref(IonButton), {
                        key: 0,
                        fill: "solid",
                        disabled: isAddingToCart.value,
                        onClick: addToCartAndClose
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" Aggiungi al carrello ")
                        ])),
                        _: 1
                      }, 8, ["disabled"]))
                    : (_openBlock(), _createBlock(_unref(IonButton), {
                        key: 1,
                        fill: "solid",
                        disabled: isAddingToCart.value,
                        onClick: addToCartAndClose
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" Salva ")
                        ])),
                        _: 1
                      }, 8, ["disabled"]))
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(IonPopover), {
        trigger: "pz-info-popover",
        "trigger-action": "click"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonContent), { class: "ion-padding" }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createElementVNode("p", null, "Il peso è indicativo. Il prodotto verrà pesato al momento della preparazione e potrebbe subire lievi variazioni.", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open", "initial-breakpoint", "onDidDismiss"]))
}
}

})