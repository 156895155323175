import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "selected-items-container" }
const _hoisted_2 = { class: "item-index" }
const _hoisted_3 = { class: "image-container" }
const _hoisted_4 = ["src", "alt"]

import {IonModal, IonHeader, IonToolbar, IonContent, IonFooter, IonButton, IonButtons,
    IonIcon, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonCardTitle,
    IonReorderGroup, IonItem, IonLabel, IonReorder, IonPopover} from '@ionic/vue';
import {closeOutline, checkmarkCircle, closeCircle, arrowBack, informationCircle} from 'ionicons/icons';
import {ref, computed, onMounted, watch} from 'vue';
import {useUserStore} from '@/stores/user';
import {useProductStore} from '@/stores/product';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'animate.css';
import config from '@/config';
import {Capacitor} from '@capacitor/core';

export default /*@__PURE__*/_defineComponent({
  __name: 'FoodPreferencesModal',
  setup(__props, { expose: __expose }) {

const userStore = useUserStore();
const productStore = useProductStore();

const showFoodPreferencesModal = ref(false);
const currentStep = ref(0);
const swiperRef = ref(null);

const likedFoods = ref([]);
const dislikedFoods = ref([]);

const closeModal = () => {
    showFoodPreferencesModal.value = false;
};
const isWebAndNotDevelopment = Capacitor.getPlatform() === 'web' && !config.development;

const categories = computed(() => productStore.allCategories);

const shouldShowModal = () => {
    const modalData = localStorage.getItem('foodPreferencesModalData');
    if (!modalData) return true;

    const {lastShown} = JSON.parse(modalData);
    const lastShownDate = new Date(lastShown);
    const currentDate = new Date();

    // Check if more than a month has passed
    const oneMonthAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    return lastShownDate < oneMonthAgo;
};

const setModalShown = () => {
    const modalData = {
        lastShown: new Date().toISOString(),
    };
    localStorage.setItem('foodPreferencesModalData', JSON.stringify(modalData));
};

onMounted(async () => {
    if (!isWebAndNotDevelopment) {
        await productStore.loadAllCategories();
        await userStore.loadFoodPreferences();
        likedFoods.value = userStore.likedFoods;
        dislikedFoods.value = userStore.dislikedFoods;

        if (!userStore.hasCompletedOnboarding && userStore.user && shouldShowModal()) {
            setTimeout(() => {
                console.log('Showing modal');
                showFoodPreferencesModal.value = true;
                setModalShown();
            }, 2000);
        }
    }
});

watch([likedFoods, dislikedFoods], () => {
    userStore.updateFoodPreferences(likedFoods.value, dislikedFoods.value);
}, {deep: true});

const toggleSelection = (category, step) => {
    const targetArray = step === 1 ? likedFoods : dislikedFoods;
    const index = targetArray.value.findIndex((item) => item.ID === category.ID);
    if (index > -1) {
        targetArray.value.splice(index, 1);
    } else {
        targetArray.value.push(category);
    }
};

const isSelected = (categoryId: string, step: number) => {
    return step === 1 ?
        likedFoods.value.some((item) => item.ID === categoryId) :
        dislikedFoods.value.some((item) => item.ID === categoryId);
};

const getSelectedItems = (step: number) => {
    return step === 1 ? likedFoods.value : dislikedFoods.value;
};

const availableCategories = (step: number) => {
    if (step === 1) {
        return categories.value;
    } else {
        return categories.value.filter((category) => !likedFoods.value.some((liked) => liked.ID === category.ID));
    }
};

const handleReorder = (event: CustomEvent, step: number) => {
    const targetArray = step === 1 ? likedFoods : dislikedFoods;
    const itemToMove = targetArray.value.splice(event.detail.from, 1)[0];
    targetArray.value.splice(event.detail.to, 0, itemToMove);
    event.detail.complete();
};

const nextStep = () => {
    if (currentStep.value < 2) {
        currentStep.value++;
        swiperRef.value.slideNext();
    } else {
        submitPreferences();
    }
};

const previousStep = () => {
    if (currentStep.value > 0) {
        currentStep.value--;
        swiperRef.value.slidePrev();
    }
};

const submitPreferences = async () => {
    await userStore.saveFoodPreferences(likedFoods.value, dislikedFoods.value);
    closeModal();
};


const getCategoryImagePath = (imgFileName: string | null) => {
    if (!imgFileName) {
    // Return a default image path if no image is set
        return require('@/assets/img/products/default.png');
    }
    // Use the API endpoint to fetch the image
    return `${config.f2BaseURL}${config.filesApi}${imgFileName}/md`;
};

const setSwiperRef = (swiper: any) => {
    swiperRef.value = swiper;
};

const canProceed = computed(() => {
    return currentStep.value === 0 || (currentStep.value === 1 && likedFoods.value.length > 0) || (currentStep.value === 2);
});


const openPreferencesModal = (showWelcome = false) => {
    currentStep.value = showWelcome ? 0 : 1;
    showFoodPreferencesModal.value = true;
};

// Expose the openPreferencesModal method
__expose({openPreferencesModal});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    "is-open": showFoodPreferencesModal.value,
    onIonModalDidDismiss: closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              (currentStep.value > 0)
                ? (_openBlock(), _createBlock(_unref(IonButtons), {
                    key: 0,
                    slot: "start"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonButton), { onClick: previousStep }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonIcon), {
                            slot: "icon-only",
                            icon: _unref(arrowBack)
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_unref(IonButtons), { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButton), { onClick: closeModal }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        slot: "icon-only",
                        icon: _unref(closeOutline)
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), {
        class: "ion-padding",
        "scroll-y": currentStep.value !== 0
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Swiper), {
            pagination: true,
            "allow-touch-move": false,
            onSwiper: setSwiperRef
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(SwiperSlide), null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("div", { class: "welcome-page" }, [
                    _createElementVNode("h1", { class: "animate__animated animate__fadeInDown" }, " Benvenuto! "),
                    _createElementVNode("div", { class: "welcome-text animate__animated animate__fadeInUp" }, [
                      _createElementVNode("p", { class: "primary-text" }, " Aiutaci a personalizzare la tua esperienza! "),
                      _createElementVNode("p", { class: "secondary-text" }, " Selezionando le tue preferenze alimentari, potremo offrirti prodotti su misura e suggerimenti pertinenti. "),
                      _createElementVNode("p", { class: "secondary-text" }, " Nelle prossime schermate, potrai indicare i cibi che ami e quelli che preferisci evitare. Questo ci permetterà di creare un'esperienza di shopping unica per te. ")
                    ])
                  ], -1)
                ])),
                _: 1
              }),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (step) => {
                return _createVNode(_unref(SwiperSlide), { key: step }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("h2", null, [
                        _createTextVNode(_toDisplayString(step === 1 ? 'Le tue preferenze' : 'Ciò che non ti piace') + " ", 1),
                        (step === 1)
                          ? (_openBlock(), _createBlock(_unref(IonIcon), {
                              key: 0,
                              id: "trigger-button",
                              icon: _unref(informationCircle)
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_unref(IonReorderGroup), {
                        disabled: false,
                        onIonItemReorder: ($event: any) => (handleReorder($event, step))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getSelectedItems(step), (item, index) => {
                            return (_openBlock(), _createBlock(_unref(IonItem), {
                              key: item.ID
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, _toDisplayString(index + 1), 1),
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.Name), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_unref(IonReorder), { slot: "end" })
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["onIonItemReorder"])
                    ]),
                    _createVNode(_unref(IonGrid), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonRow), null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableCategories(step), (category, index) => {
                              return (_openBlock(), _createBlock(_unref(IonCol), {
                                key: index,
                                "size-xs": "6",
                                "size-sm": "4",
                                "size-md": "3"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(IonCard), {
                                    class: _normalizeClass({ selected: isSelected(category.ID, step),
                                              'animate__animated animate__pulse': isSelected(category.ID, step) }),
                                    onClick: ($event: any) => (toggleSelection(category, step))
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_3, [
                                        _createElementVNode("img", {
                                          src: getCategoryImagePath(category.img),
                                          alt: category.Name
                                        }, null, 8, _hoisted_4),
                                        (isSelected(category.ID, step))
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 0,
                                              class: _normalizeClass(["selection-overlay", { dislike: step === 2 }])
                                            }, [
                                              _createVNode(_unref(IonIcon), {
                                                icon: step === 1 ? _unref(checkmarkCircle) : _unref(closeCircle),
                                                class: "selection-icon"
                                              }, null, 8, ["icon"])
                                            ], 2))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _createVNode(_unref(IonCardContent), null, {
                                        default: _withCtx(() => [
                                          _createVNode(_unref(IonCardTitle), { class: "centered-title" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(category.Name), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["class", "onClick"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              }), 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["scroll-y"]),
      _createVNode(_unref(IonFooter), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonButton), {
                expand: "block",
                disabled: !canProceed.value,
                onClick: nextStep
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(currentStep.value === 2 ? 'Salva' : 'Avanti'), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonPopover), {
        trigger: "trigger-button",
        "trigger-action": "click"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonContent), { class: "ion-padding" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("h3", null, "Ordine delle preferenze", -1),
              _createElementVNode("p", null, "L'ordine conta! Le categorie in cima avranno priorità nei nostri suggerimenti.", -1),
              _createElementVNode("p", null, "Trascina gli elementi per riordinarli secondo le tue priorità.", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

})