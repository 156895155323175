import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
    IonLoading,
    alertController,
} from '@ionic/vue';
import {closeOutline} from 'ionicons/icons';
import {ref} from 'vue';
import {useUserStore} from '../stores/user';
import {useCustomerStore} from '@/stores/customer';

import ProvinceSelectorModal from './ProvinceSelectorModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateBusiness',
  setup(__props) {

const provinceSelector = ref(null);
const selectedProvince = ref('');
const isLoading = ref(false);

const openProvinceSelector = async () => {
    if (userStore.provinces.length === 0) {
        await userStore.fetchProvinces();
    }
    provinceSelector.value.isOpen = true;
};

const handleProvinceSelection = (province: string) => {
    selectedProvince.value = province;
};

const validateEmail = (email: string) => {
    const re = /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
};

const validatePartitaIva = (partitaIva: string) => {
    if (partitaIva.length !== 11 || !/^\d+$/.test(partitaIva)) {
        return false;
    }

    const cifre = partitaIva.split('').map((c) => parseInt(c, 10));
    const totaleDispari = cifre.filter((_, i) => i % 2 === 0).reduce((acc, n) => acc + n, 0);
    const totalePari = cifre.filter((_, i) => i % 2 !== 0)
        .map((n) => (n * 2 > 9 ? n * 2 - 9 : n * 2))
        .reduce((acc, n) => acc + n, 0);
    const totale = totaleDispari + totalePari;

    return totale % 10 === 0;
};

const userStore = useUserStore();
const newCompany = ref({
    name: '',
    username: '',
    partitaIva: '',
    contactEmail: '',
    descrizione: '',
    address: '',
    citta: '',
    cap: '',
    provincia: '',
    contactNumber: '',
});

const closeModal = () => {
    userStore.toggleCreateBusinessModal(false);
    newCompany.value = {
        name: '',
        username: '',
        partitaIva: '',
        contactEmail: '',
        descrizione: '',
        address: '',
        citta: '',
        cap: '',
        provincia: '',
        contactNumber: '',
    };
};

const creaAzienda = async () => {
    const customerStore = useCustomerStore();

    if (!validateEmail(newCompany.value.contactEmail)) {
        await alertController.create({
            header: 'Errore',
            message: 'L\'email fornita non è valida. Per favore, correggila.',
            buttons: ['OK'],
        }).then((alert) => alert.present());
        return;
    }

    if (!validatePartitaIva(newCompany.value.partitaIva)) {
        await alertController.create({
            header: 'Errore',
            message: 'La Partita IVA fornita non è valida. Per favore, correggila.',
            buttons: ['OK'],
        }).then((alert) => alert.present());
        return;
    }

    const duplicateFields = {
        username: newCompany.value.username,
        contactNumber: newCompany.value.contactNumber,
        contactEmail: newCompany.value.contactEmail,
        VAT_Number: newCompany.value.partitaIva,
    };

    const isDuplicate = await customerStore.checkForDuplicateCompany(duplicateFields);
    if (isDuplicate) {
        await alertController.create({
            header: 'Errore',
            message: 'I dati forniti sono già presenti nel database. Per favore, correggili.',
            buttons: ['OK'],
        }).then((alert) => alert.present());
        return;
    }

    const alert = await alertController.create({
        header: 'Vuoi creare un\'azienda?',
        message: 'Se clicchi sì, verrai disconnesso dall\'azienda corrente.',
        buttons: [
            {
                text: 'No',
                role: 'cancel',
                handler: () => {
                    console.log('Invitation cancelled');
                },
            },
            {
                text: 'Si',
                handler: async () => {
                    const input = {
                        VAT_Number: newCompany.value.partitaIva,
                        Name: newCompany.value.name,
                        username: newCompany.value.username,
                        Description: newCompany.value.descrizione,
                        Address: newCompany.value.address,
                        Province: selectedProvince.value,
                        Active: true,
                        users: {
                            connect: [
                                {
                                    where: {
                                        node: {
                                            F2_ID: userStore.userAdditionalData?.F2_ID,
                                        },
                                    },
                                },
                            ],
                        },
                    };

                    await customerStore.createCustomerWithUser([input]);
                    closeModal();
                    window.location.reload();
                },
            },
        ],
    });

    await alert.present();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    "is-open": _unref(userStore).showCreateBusinessModal,
    onIonModalDidDismiss: closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTitle), null, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Crea Azienda")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonButtons), { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButton), { onClick: closeModal }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), { icon: _unref(closeOutline) }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" Partita IVA: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.partitaIva,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newCompany.value.partitaIva) = $event)),
                required: "",
                "aria-label": "Partita IVA"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode(" Nome dell'azienda: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newCompany.value.name) = $event)),
                required: "",
                "aria-label": "Nome dell'azienda"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode(" Nome utente: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.username,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newCompany.value.username) = $event)),
                required: "",
                "aria-label": "Nome utente"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" Descrizione: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.descrizione,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newCompany.value.descrizione) = $event)),
                required: "",
                "aria-label": "Descrizione"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode(" Numero di contatto: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.contactNumber,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((newCompany.value.contactNumber) = $event)),
                "aria-label": "Numero di contatto"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode(" Email di contatto: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.contactEmail,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((newCompany.value.contactEmail) = $event)),
                type: "email",
                "aria-label": "Email di contatto"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode(" Indirizzo: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.address,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((newCompany.value.address) = $event)),
                required: "",
                "aria-label": "Indirizzo"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode(" Città: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.citta,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((newCompany.value.citta) = $event)),
                required: "",
                "aria-label": "Città"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode(" CAP: ")
                ])),
                _: 1
              }),
              _createVNode(_unref(IonInput), {
                modelValue: newCompany.value.cap,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((newCompany.value.cap) = $event)),
                required: "",
                "aria-label": "CAP"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonItem), { onClick: openProvinceSelector }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), { position: "stacked" }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode(" Provincia: ")
                ])),
                _: 1
              }),
              _createTextVNode(_toDisplayString(selectedProvince.value || 'Seleziona una Provincia'), 1)
            ]),
            _: 1
          }),
          _createVNode(ProvinceSelectorModal, {
            ref_key: "provinceSelector",
            ref: provinceSelector,
            provinces: _unref(userStore).provinces,
            "onUpdate:selectedProvince": handleProvinceSelection
          }, null, 8, ["provinces"]),
          _createVNode(_unref(IonButton), {
            expand: "block",
            fill: "outline",
            onClick: creaAzienda
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode(" Crea azienda ")
            ])),
            _: 1
          }),
          _createVNode(_unref(IonLoading), {
            "is-open": isLoading.value,
            message: "Sending invitation..."
          }, null, 8, ["is-open"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

})