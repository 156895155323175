<template>
    <ion-modal
        :is-open="showFoodPreferencesModal"
        @ionModalDidDismiss="closeModal"
    >
        <ion-header>
            <ion-toolbar>
                <ion-buttons
                    v-if="currentStep > 0"
                    slot="start"
                >
                    <ion-button @click="previousStep">
                        <ion-icon
                            slot="icon-only"
                            :icon="arrowBack"
                        />
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button @click="closeModal">
                        <ion-icon
                            slot="icon-only"
                            :icon="closeOutline"
                        />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content
            class="ion-padding"
            :scroll-y="currentStep !== 0"
        >
            <swiper
                :pagination="true"
                :allow-touch-move="false"
                @swiper="setSwiperRef"
            >
                <swiper-slide>
                    <div class="welcome-page">
                        <h1 class="animate__animated animate__fadeInDown">
                            Benvenuto!
                        </h1>
                        <div class="welcome-text animate__animated animate__fadeInUp">
                            <p class="primary-text">
                                Aiutaci a personalizzare la tua esperienza!
                            </p>
                            <p class="secondary-text">
                                Selezionando le tue preferenze alimentari, potremo offrirti prodotti su misura e suggerimenti pertinenti.
                            </p>
                            <p class="secondary-text">
                                Nelle prossime schermate, potrai indicare i cibi che ami e quelli che preferisci evitare.
                                Questo ci permetterà di creare un'esperienza di shopping unica per te.
                            </p>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide
                    v-for="step in 2"
                    :key="step"
                >
                    <div class="selected-items-container">
                        <h2>
                            {{ step === 1 ? 'Le tue preferenze' : 'Ciò che non ti piace' }}
                            <ion-icon
                                v-if="step === 1"
                                id="trigger-button"
                                :icon="informationCircle"
                            />
                        </h2>
                        <ion-reorder-group
                            :disabled="false"
                            @ionItemReorder="handleReorder($event, step)"
                        >
                            <ion-item
                                v-for="(item, index) in getSelectedItems(step)"
                                :key="item.ID"
                            >
                                <div class="item-index">
                                    {{ index + 1 }}
                                </div>
                                <ion-label>{{ item.Name }}</ion-label>
                                <ion-reorder slot="end" />
                            </ion-item>
                        </ion-reorder-group>
                    </div>
                    <ion-grid>
                        <ion-row>
                            <ion-col
                                v-for="(category, index) in availableCategories(step)"
                                :key="index"
                                size-xs="6"
                                size-sm="4"
                                size-md="3"
                            >
                                <ion-card
                                    :class="{ selected: isSelected(category.ID, step),
                                              'animate__animated animate__pulse': isSelected(category.ID, step) }"
                                    @click="toggleSelection(category, step)"
                                >
                                    <div class="image-container">
                                        <img
                                            :src="getCategoryImagePath(category.img)"
                                            :alt="category.Name"
                                        >
                                        <div
                                            v-if="isSelected(category.ID, step)"
                                            class="selection-overlay"
                                            :class="{ dislike: step === 2 }"
                                        >
                                            <ion-icon
                                                :icon="step === 1 ? checkmarkCircle : closeCircle"
                                                class="selection-icon"
                                            />
                                        </div>
                                    </div>
                                    <ion-card-content>
                                        <ion-card-title class="centered-title">
                                            {{ category.Name }}
                                        </ion-card-title>
                                    </ion-card-content>
                                </ion-card>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </swiper-slide>
            </swiper>
        </ion-content>

        <ion-footer>
            <ion-toolbar>
                <ion-button
                    expand="block"
                    :disabled="!canProceed"
                    @click="nextStep"
                >
                    {{ currentStep === 2 ? 'Salva' : 'Avanti' }}
                </ion-button>
            </ion-toolbar>
        </ion-footer>

        <ion-popover
            trigger="trigger-button"
            trigger-action="click"
        >
            <ion-content class="ion-padding">
                <h3>Ordine delle preferenze</h3>
                <p>L'ordine conta! Le categorie in cima avranno priorità nei nostri suggerimenti.</p>
                <p>Trascina gli elementi per riordinarli secondo le tue priorità.</p>
            </ion-content>
        </ion-popover>
    </ion-modal>
</template>

<script setup lang="ts">
import {IonModal, IonHeader, IonToolbar, IonContent, IonFooter, IonButton, IonButtons,
    IonIcon, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonCardTitle,
    IonReorderGroup, IonItem, IonLabel, IonReorder, IonPopover} from '@ionic/vue';
import {closeOutline, checkmarkCircle, closeCircle, arrowBack, informationCircle} from 'ionicons/icons';
import {ref, computed, onMounted, watch} from 'vue';
import {useUserStore} from '@/stores/user';
import {useProductStore} from '@/stores/product';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'animate.css';
import config from '@/config';
import {Capacitor} from '@capacitor/core';
const userStore = useUserStore();
const productStore = useProductStore();

const showFoodPreferencesModal = ref(false);
const currentStep = ref(0);
const swiperRef = ref(null);

const likedFoods = ref([]);
const dislikedFoods = ref([]);

const closeModal = () => {
    showFoodPreferencesModal.value = false;
};
const isWebAndNotDevelopment = Capacitor.getPlatform() === 'web' && !config.development;

const categories = computed(() => productStore.allCategories);

const shouldShowModal = () => {
    const modalData = localStorage.getItem('foodPreferencesModalData');
    if (!modalData) return true;

    const {lastShown} = JSON.parse(modalData);
    const lastShownDate = new Date(lastShown);
    const currentDate = new Date();

    // Check if more than a month has passed
    const oneMonthAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    return lastShownDate < oneMonthAgo;
};

const setModalShown = () => {
    const modalData = {
        lastShown: new Date().toISOString(),
    };
    localStorage.setItem('foodPreferencesModalData', JSON.stringify(modalData));
};

onMounted(async () => {
    if (!isWebAndNotDevelopment) {
        await productStore.loadAllCategories();
        await userStore.loadFoodPreferences();
        likedFoods.value = userStore.likedFoods;
        dislikedFoods.value = userStore.dislikedFoods;

        if (!userStore.hasCompletedOnboarding && userStore.user && shouldShowModal()) {
            setTimeout(() => {
                console.log('Showing modal');
                showFoodPreferencesModal.value = true;
                setModalShown();
            }, 2000);
        }
    }
});

watch([likedFoods, dislikedFoods], () => {
    userStore.updateFoodPreferences(likedFoods.value, dislikedFoods.value);
}, {deep: true});

const toggleSelection = (category, step) => {
    const targetArray = step === 1 ? likedFoods : dislikedFoods;
    const index = targetArray.value.findIndex((item) => item.ID === category.ID);
    if (index > -1) {
        targetArray.value.splice(index, 1);
    } else {
        targetArray.value.push(category);
    }
};

const isSelected = (categoryId: string, step: number) => {
    return step === 1 ?
        likedFoods.value.some((item) => item.ID === categoryId) :
        dislikedFoods.value.some((item) => item.ID === categoryId);
};

const getSelectedItems = (step: number) => {
    return step === 1 ? likedFoods.value : dislikedFoods.value;
};

const availableCategories = (step: number) => {
    if (step === 1) {
        return categories.value;
    } else {
        return categories.value.filter((category) => !likedFoods.value.some((liked) => liked.ID === category.ID));
    }
};

const handleReorder = (event: CustomEvent, step: number) => {
    const targetArray = step === 1 ? likedFoods : dislikedFoods;
    const itemToMove = targetArray.value.splice(event.detail.from, 1)[0];
    targetArray.value.splice(event.detail.to, 0, itemToMove);
    event.detail.complete();
};

const nextStep = () => {
    if (currentStep.value < 2) {
        currentStep.value++;
        swiperRef.value.slideNext();
    } else {
        submitPreferences();
    }
};

const previousStep = () => {
    if (currentStep.value > 0) {
        currentStep.value--;
        swiperRef.value.slidePrev();
    }
};

const submitPreferences = async () => {
    await userStore.saveFoodPreferences(likedFoods.value, dislikedFoods.value);
    closeModal();
};


const getCategoryImagePath = (imgFileName: string | null) => {
    if (!imgFileName) {
    // Return a default image path if no image is set
        return require('@/assets/img/products/default.png');
    }
    // Use the API endpoint to fetch the image
    return `${config.f2BaseURL}${config.filesApi}${imgFileName}/md`;
};

const setSwiperRef = (swiper: any) => {
    swiperRef.value = swiper;
};

const canProceed = computed(() => {
    return currentStep.value === 0 || (currentStep.value === 1 && likedFoods.value.length > 0) || (currentStep.value === 2);
});


const openPreferencesModal = (showWelcome = false) => {
    currentStep.value = showWelcome ? 0 : 1;
    showFoodPreferencesModal.value = true;
};

// Expose the openPreferencesModal method
defineExpose({openPreferencesModal});
</script><style scoped>
  @import 'animate.css';

  @import 'animate.css';

.welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
}

.welcome-page h1 {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 30px;
  color: var(--ion-color-primary);
}

.welcome-text {
  max-width: 600px;
  margin: 0 auto;
}

.primary-text {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.secondary-text {
  font-size: 18px;
  color: var(--ion-color-medium);
  margin-bottom: 15px;
  line-height: 1.5;
}

  .selected-items-container {
    margin-bottom: 20px;
  }

  .selected-items-container h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
    color: var(--ion-color-primary);
  }

  .selected-items-container h2 ion-icon {
    margin-left: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  ion-reorder-group {
    max-height: 30vh;
    overflow-y: auto;
  }

  .item-index {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--ion-color-primary);
    color: white;
    font-weight: bold;
    margin-right: 12px;
  }

  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    overflow: hidden;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .selection-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-primary);
  opacity: 0.8;
  transition: all 0.3s ease;
}


  .selection-overlay.dislike {
    background-color: rgba(255, 0, 0, 0.6);
  }

  .selection-icon {
    font-size: 48px;
    color: white;
  }

  .centered-title {
    font-size: 14px;
    text-align: center;
    width: 100%;
    color: var(--ion-color-dark);
  }

  ion-col {
    padding: 8px;
  }

  swiper {
    height: 100%;
  }

  ion-card {
    margin: 0;
    height: 100%;
    transition: all 0.3s ease;
  }

  ion-card.selected {
    transform: scale(0.95);
    box-shadow: 0 0 0 3px var(--ion-color-primary);
  }

  ion-footer ion-toolbar {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
  }

  ion-footer ion-button {
    margin: 0;
  }
  </style>
