import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

import {useRouter} from 'vue-router';
import {computed} from 'vue';
import {Capacitor} from '@capacitor/core';
import config from '../config'; // Adjust the path as necessary
import {useCartStore} from '@/shared/store/cartStore'; // Adjust the path as necessary
import {watch, ref} from 'vue';
import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonBadge,
} from '@ionic/vue';
import {basketOutline, homeOutline, personOutline, notificationsOutline, addOutline, cartOutline} from 'ionicons/icons';
import {useUserStore} from '../stores/user';
import FoodPreferencesModal from '@/components/FoodPreferencesModal.vue';
import {onMounted} from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'TabsPage',
  setup(__props) {

const animateBadge = ref(false);

const cartStore = useCartStore();

const isWebAndNotDevelopment = Capacitor.getPlatform() === 'web' && !config.development;
const foodPreferencesModalRef = ref(null);

const router = useRouter();


const delayedCartItemCount = ref(cartStore.cartItemCount);
// if we are not in web
if (!isWebAndNotDevelopment) {
    cartStore.fetchCartItems();
}
watch(() => cartStore.cartItemCount, (newCount, oldCount) => {
    if (newCount > oldCount) {
        // Set the animation trigger

        setTimeout(() => {
            delayedCartItemCount.value = newCount;
            animateBadge.value = true;
        }, 500); // Match this delay to the start of the animation// Delay resetting the animation trigger to allow the animation to be visible
        setTimeout(() => {
            animateBadge.value = false;
        }, 3000); // Consider the animation duration + delay

        // Delay the badge number update to match the animation start
    } else {
        // For cases where the count decreases or instant update is needed without animation
        delayedCartItemCount.value = newCount;
    }
});


const profileTabHref = computed(() => {
    return store.isRestaurantUser ? '/tabs/personal-profile' : '/profile-view/';
});
const handleProductsTabClick = () => {
    if (router.currentRoute.value.path === '/tabs/product-view') {
        const topElement = document.getElementById('topOfProducts');
        if (topElement) {
            topElement.scrollIntoView({behavior: 'smooth'});
        }
    } else {
        router.push('/tabs/product-view');
    }
};

const handleContentsTabClick = () => {
    if (router.currentRoute.value.path === '/tabs/content-list') {
        // Use vanilla JavaScript to scroll to the 'topOfPage' element
        const topElement = document.getElementById('topOfPage');
        if (topElement) {
            topElement.scrollIntoView({behavior: 'smooth'});
        }
    } else {
        // Otherwise, navigate to the content-list page
        router.push('/tabs/content-list');
    }
};

const store = useUserStore();
const isTabSelected = (tab: string) => {
    const currentPath = router.currentRoute.value.path;
    const pathMap: Record<string, string[]> = {
        'contents': ['/tabs/content-list'],
        'products': ['/tabs/product-view'],
        'cart': ['/tabs/cart-view'],
        'post': ['/tabs/post-view'],
        'profile': ['/tabs/personal-profile', '/profile-view/'],
        'notification': ['/tabs/notification-view'],
    };
    return pathMap[tab]?.includes(currentPath) || false;
};
const showModal = () => {
    let modalShown = undefined;

    try {
        modalShown = sessionStorage.getItem('loadFoodPreferences');
    } catch (error) {
        console.log('loadFoodPreferences not found');
    }

    store.loadFoodPreferences().then(() => {
        if (store.isRestaurantUser && !store.isSupplierUser && !store.hasCompletedOnboarding && !modalShown) {
            if (foodPreferencesModalRef.value) {
                foodPreferencesModalRef.value.openPreferencesModal(true);
            } else {
                console.warn('foodPreferencesModalRef.value is null or undefined');
            }

            try {
                sessionStorage.setItem('loadFoodPreferences', 'true');
            } catch (error) {
                console.error('Error setting item in sessionStorage:', error);
            }
        } else {
            console.log('Conditions not met to open preferences modal');
        }
    }).catch((error) => {
        console.error('Error in loadFoodPreferences:', error);
    });

    console.log('Exiting showModal function');
};

onMounted(() => {
    console.log('Component mounted');
    if (Capacitor.getPlatform() !== 'web') {
        showModal();
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          (!_unref(isWebAndNotDevelopment))
            ? (_openBlock(), _createBlock(_unref(IonTabBar), {
                key: 0,
                slot: "bottom",
                class: "minimal-tab-bar"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonTabButton), {
                    tab: "contents",
                    href: "/tabs/content-list",
                    class: _normalizeClass(["tab-button", { 'tab-selected': isTabSelected('contents') }]),
                    onClick: handleContentsTabClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        "aria-hidden": "true",
                        icon: _unref(homeOutline)
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  (_unref(store).isRestaurantUser && _unref(store).hasCurrentCompany)
                    ? (_openBlock(), _createBlock(_unref(IonTabButton), {
                        key: 0,
                        tab: "products",
                        href: "/tabs/product-view",
                        class: _normalizeClass(["tab-button", { 'tab-selected': isTabSelected('products') }]),
                        onClick: handleProductsTabClick
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonIcon), {
                            "aria-hidden": "true",
                            icon: _unref(basketOutline)
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_unref(store).hasCurrentCompany && _unref(store).isRestaurantUser)
                    ? (_openBlock(), _createBlock(_unref(IonTabButton), {
                        key: 1,
                        tab: "cart",
                        href: "/tabs/cart-view",
                        class: _normalizeClass(["tab-button", { 'tab-selected': isTabSelected('cart') }])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonIcon), {
                            "aria-hidden": "true",
                            icon: _unref(cartOutline)
                          }, null, 8, ["icon"]),
                          (delayedCartItemCount.value)
                            ? (_openBlock(), _createBlock(_unref(IonBadge), {
                                key: 0,
                                class: _normalizeClass({ 'animate-badge': animateBadge.value })
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(delayedCartItemCount.value), 1)
                                ]),
                                _: 1
                              }, 8, ["class"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_unref(store).isSupplierUser)
                    ? (_openBlock(), _createBlock(_unref(IonTabButton), {
                        key: 2,
                        tab: "post",
                        href: "/tabs/post-view",
                        class: _normalizeClass(["tab-button", { 'tab-selected': isTabSelected('post') }])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonIcon), {
                            "aria-hidden": "true",
                            icon: _unref(addOutline)
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(IonTabButton), {
                    tab: "profile",
                    href: profileTabHref.value,
                    class: _normalizeClass(["tab-button", { 'tab-selected': isTabSelected('profile') }])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        "aria-hidden": "true",
                        icon: _unref(personOutline)
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["href", "class"]),
                  _createVNode(_unref(IonTabButton), {
                    tab: "notification",
                    href: "/tabs/notification-view",
                    class: _normalizeClass(["tab-button", { 'tab-selected': isTabSelected('notification') }])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        "aria-hidden": "true",
                        icon: _unref(notificationsOutline)
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(FoodPreferencesModal, {
        ref_key: "foodPreferencesModalRef",
        ref: foodPreferencesModalRef
      }, null, 512)
    ]),
    _: 1
  }))
}
}

})